.OutputPageWrapper {
  width: 40%;
  color:var(--formfield-text-color);
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  min-width: 300px;
}

.OutputPageWrapper H1{
  padding-left:4%;
  padding-right:4%;
}

@media screen and (max-width: 700px) {
  .OutputPageWrapper {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    min-width: 300px;
  }
  .OutputPageWrapper H1{
    padding-left:4%;
    padding-right:4%;
    font-size:40px;
  }
}

.ThingsCardLiked {
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 16px;
  color: var(--formfieldLiked-text-color);
  background: var(--thingsCardLiked-background);
  padding: 4%;
  box-shadow: var(--cardShadow);
}

.ThingsCardLiked a{
  color:var(--formfieldLiked-text-color);
}

.ThingsCardLiked button{
  color:var(--button-link-color-inverse);
}
.ThingsCardLiked button:hover{
  color:var(--button-link-color-inverse-hover);
}

.ThingsCardLikedNoBG {
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 16px;
  color: var(--formfieldLiked-text-color-inverse);
  padding: 4%;
}

.ThingsCardLikedNoBG a{
  color:var(--formfieldLiked-text-color-inverse);
}

.ThingsCardLikedNoBG button{
  color:var(--button-link-color);
}

.PostDataWrapperLiked button{
  color: var(--button-link-color-inverse)!important;
}

.PostDataWrapperLiked button:hover {
  color: var(--button-link-color-inverse-hover);
}

.ThingsCard {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 4%;
  z-index: 100;
}

.ThingsContainer {
}

.ThingsContainerLiked {
}

/*Form Fields*/

.FormFields{
}

.FormFields > div {
  flex-direction: row;
  padding: 16px;
  background-color: var(--formfield-background);
  color:var(--formfield-text-color);
  border: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 120%;
  margin-bottom: 12px;
}

.FormFields > div:last-child {
  margin-bottom: 2px;
}

/*Form Fields Liked*/
.FormFieldsLiked > div {
  flex-direction: row;
  align-items: stretch;
  display:flex;
  padding: 16px;
  background: var(--formfieldLiked-background);
  color: var(--formfieldLiked-text-color);
  border: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 120%;
  margin-bottom: 12px;
}


.FormFieldsLiked > div:last-child {
  margin-bottom: 2px;
}

/* Form Fields Liked NO BG*/
.FormFieldsLikedNoBG{
  }

.FormFieldsLikedNoBG > div {
  flex-direction: row;
  padding: 16px;
  background: var(--formfieldLikedNOBG-background);
  color: var(--formfield-text-color-inverse);
  border: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 120%;
  margin-bottom: 12px;
}


.FormFieldsLikedNoBG > div:last-child {
  margin-bottom: 2px;
}


/*Below the form fields */
.PostDataWrapperLiked {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.PostDataWrapperLiked button{
  color:var(--formfield-text-color-inverse)!important;
}
.PostDataWrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}


.thingsInfoWrapper {
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}

.PostData button {
  font-size: 10px;
  font-style: italic;
  font-weight: normal;
}

/*right half of info wrapper */
/* icon, likes and flag button */
.faceCountWrapper {
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  align-items: center;
}

/* icon and likes */
.faceCountIcon {
  display: flex;
  justify-content: center;
  align-content: center;
}

.IconsWrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}

.IconsWrapper > div {
  opacity: 0.75;
}

.IconsWrapper > div:hover {
  fill: rgb(251, 254, 251);
  opacity: 1;
}

.faceCount a {
  text-decoration: none;
}

.faceCount a:hover {
  text-decoration: underline;
}

/* icons */

.elatedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.elatedContainer path {
}

.faceFill path {
  fill: #ffffff;
}

.smallText {
  font-size: 10px !important;
  line-height: 140% !important;
}
