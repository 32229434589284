
.InputForm{
  width: 36%;
  margin-left: auto;
  margin-right: auto;
  margin-top:4%;
  min-width:300px;
  max-width:640px;
  color:var(--formfield-text-color);
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  }

  .InputForm H1{
    margin-top:0px;
  }


  @media screen and (max-width: 700px) {
    .InputForm{
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      min-width:300px;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: hidden;
      }
      .InputForm H1{
            font-size:40px;
            margin-top:72px;
          }
  }

.FormElementWrapper{
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

::placeholder{
  color:var(--text-link-color);
  opacity:.25;
}

.FormElement {
  display: flex;
  flex-direction: row;
  padding: 16px;
  margin-bottom: 12px;
  background-image:none !important;
  background:var(--formfield-background);
  border-radius: 0px;
  border: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 120%;
  color: var(--formfield-text-color);
}

.FormElement .Error{
  background:var(--formfield-background-color-error);
}


.FormElement:hover {
    background:var(--formfield-background-color-hover);
}

.ErrorText {
  font-size: 14px;
  font-weight:700;
  color: var(--error-red);
  transition: all 2s;
}

.TextCTA, .MobileTextCTA {
  position: relative;
  padding-left: 0px;
  background-color: transparent;
  color:var(--input-text-color);
  font-size: 24px;
  letter-spacing: -.5px;
  font-weight: 700;
  text-decoration: none;
  border:none;
}



.TextCTA:hover {
  cursor:pointer;
  background: var(--input-background);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.MobileTextCTA:hover{
  color: var(--button-link-color);
}

#anonymousMessage {
  display: flex;
  align-items:flex-start;
}


/*input checkbox styling*/

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + span {
  display: inline-block;
  position: relative;
  top: 0px;
  width: 16px;
  height: 16px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background-color: var(--button-link-color);
  -webkit-mask-image: url('./../img/unchecked.svg');
  mask-image: url('./../img/unchecked.svg');
  cursor: pointer;
  opacity:.5;
}
input[type="checkbox"]:checked + span {
  background-color: var(--button-link-color);
  -webkit-mask-image: url('./../img/checked.svg');
  mask-image: url('./../img/checked.svg');
  opacity:1;
}

input[type="checkbox"] + span {
  margin-right: 4px;
}

.inputCheckbox{
  margin-top:0px;
  margin-bottom:12px;
  text-align:left;
}

.inputCheckboxHidden{
  display:none;
}
