[data-theme="dark"] {
  /*mobile*/
  --topNav-background-color: rgba(36, 39, 47,.92);
  --mobileNav-background: rgba(36, 39, 47);

  /*basic*/
  --error-red:rgba(255, 86, 48);
  --error-white:rgba(251,254,251);
  --faint-lines: rgba(251,254,251, .24);
  --text-color: rgba(251, 254, 251);
  --background: rgba(36, 39, 47, 1);
  --overlay-background: rgba(36, 39, 47, 0.75);
  --bright-gradient: linear-gradient(
    30deg,
    rgba(255, 168, 202, 1) 0%,
    rgba(255, 181, 169, 1) 0%,
    rgba(255, 198, 123, 1) 36%,
    rgba(255, 220, 98, 1) 75%,
    rgba(255, 226, 50, 1) 100%
  );

  /*formfield text*/
  --input-text-color: rgba(251, 254, 251, 1);
  --formfield-text-color: rgba(251, 254, 251, 0.75);
  --formfieldLiked-text-color: rgba(36, 39, 47, 1);
  --formfieldLiked-text-color-inverse: rgba(251, 254, 251);
  /*formfield background*/
  --formfield-background: rgba(251, 254, 251, 0.08);
  --formfield-background-color-hover: rgba(251, 254, 251, 0.1);
  --formfieldLiked-background: rgba(251, 254, 251, 0.24);
  --formfieldLikedNOBG-background: rgba(251, 254, 251, 0.08);
  --formfield-background-color-error: rgba(255, 86,.08);
  /*links*/
  --link-text-color: rgba(251, 254, 251);
  --link-text-color-hover: rgba(251, 254, 251, 1);
  /*buttons*/
  --button-link-color: rgba(251, 254, 251);
  --button-link-color-hover: rgba(251, 254, 251);
  --button-link-color-inverse: rgba(36, 39, 47);
  --button-link-color-inverse-hover: rgba(36, 39, 47);
  /*backgrounds*/
  --thingsCardLiked-background: linear-gradient(
    30deg,
    rgba(255, 168, 202, 1) 0%,
    rgba(255, 181, 169, 1) 0%,
    rgba(255, 198, 123, 1) 36%,
    rgba(255, 220, 98, 1) 75%,
    rgba(255, 226, 50, 1) 100%
  );
  --input-background: linear-gradient(
    30deg,
    rgba(255, 168, 202, 1) 0%,
    rgba(255, 181, 169, 1) 0%,
    rgba(255, 198, 123, 1) 36%,
    rgba(255, 220, 98, 1) 75%,
    rgba(255, 226, 50, 1) 100%
  );
  /*misc styles*/
  --cardShadow: 0 0.8px 1.5px rgba(0, 0, 0, 0.14),
    0 2.7px 5.1px rgba(0, 0, 0, 0.17), 0 12px 23px rgba(0, 0, 0, 0.28);
}

[data-theme="light"] {
  /*mobile*/
  --topNav-background-color:rgba(251,254,251,.92);
  --topNav-background-color-hidden: inherit;
  --mobileNav-background:
  rgba(251, 254, 251);
  /*basic*/
  --error-red:rgba(255, 86, 48);
  --error-white:rgba(251,254,251);
  --faint-lines: rgba(36, 39, 47, .24);
  --text-color: rgba(36, 39, 47);
  --background: rgba(251, 254, 251, 1);
  --overlay-background: rgba(251, 254, 251, .75);
  --bright-gradient: linear-gradient(
    30deg,
    rgba(255, 168, 202, 1) 0%,
    rgba(255, 181, 169, 1) 0%,
    rgba(255, 198, 123, 1) 36%,
    rgba(255, 220, 98, 1) 75%,
    rgba(255, 226, 50, 1) 100%
  );

  /*formfield text*/
  --input-text-color: rgba(36, 39, 47, 1);
  --formfield-text-color: rgba(36, 39, 47);
  --formfieldLiked-text-color: rgba(36, 39, 47);
  --formfieldLiked-text-color-inverse: rgba(36, 39, 47);
  /*formfield background*/
  --formfield-background: rgba(35, 39, 47, 0.08);
  --formfield-background-color-hover: rgba(36, 39, 47, 0.1);
  --formfieldLiked-background: rgba(251, 254, 251, 0.24);
  --formfieldLikedNOBG-background: rgba(36, 39, 47, 0.08);
  --formfield-background-color-error: rgba(255, 86,.08);
  /*links*/
  --link-text-color: rgba(36, 39, 47);
  --link-text-color-hover: rgba(36, 39, 47, 1);
  /*buttons*/
  --button-link-color: rgba(36, 39, 47);
  --button-link-color-hover: rgba(36, 39, 47);
  --button-link-color-inverse: rgba(251, 254, 251);
  --button-link-color-inverse-hover: rgba(251, 254, 251);
  /*backgrounds*/
  --thingsCardLiked-background: linear-gradient(
    30deg,
    rgba(255, 168, 202, 1) 0%,
    rgba(255, 181, 169, 1) 0%,
    rgba(255, 198, 123, 1) 36%,
    rgba(255, 220, 98, 1) 75%,
    rgba(255, 226, 50, 1) 100%
  );
  --input-background: linear-gradient(
    30deg,
    rgba(255, 168, 202, 1) 0%,
    rgba(255, 181, 169, 1) 0%,
    rgba(255, 198, 123, 1) 36%,
    rgba(255, 220, 98, 1) 75%,
    rgba(255, 226, 50, 1) 100%
  );
  /*misc styles*/
  --cardShadow: 0 0.8px 1.5px rgba(0, 0, 0, 0.04),
    0 2.7px 5.1px rgba(0, 0, 0, 0.07), 0 12px 23px rgba(0, 0, 0, 0.09);
}

[data-theme="bright"] {
  /*mobile*/
  --topNav-background-color: linear-gradient(-90deg, rgb(255,225,61),rgb(255,216,102));
  --topNav-background-color-hidden: inherit;
  --mobileNav-background: linear-gradient(
    30deg,
    rgba(255, 168, 202) 0%,
    rgba(255, 181, 169) 0%,
    rgba(255, 198, 123) 36%,
    rgba(255, 220, 98) 75%,
    rgba(255, 226, 50) 100%
  );
  /*basic*/
  --error-red:rgba(255, 86, 48);
  --error-white:rgba(251,254,251);
  --text-color: rgba(36, 39, 47);
  --background: linear-gradient(
    30deg,
    rgba(255, 168, 202, .75) 0%,
    rgba(255, 181, 169, .75) 0%,
    rgba(255, 198, 123, .75) 36%,
    rgba(255, 220, 98, .75) 75%,
    rgba(255, 226, 50, .75) 100%
  );
  --faint-lines: rgba(36, 39, 47, .24);
  --bright-gradient: linear-gradient(
    30deg,
    rgba(255, 168, 202, 1) 0%,
    rgba(255, 181, 169, 1) 0%,
    rgba(255, 198, 123, 1) 36%,
    rgba(255, 220, 98, 1) 75%,
    rgba(255, 226, 50, 1) 100%
  );
  --overlay-background:linear-gradient(
    30deg,
    rgba(255, 168, 202, .75) 0%,
    rgba(255, 181, 169, .75) 0%,
    rgba(255, 198, 123, .75) 36%,
    rgba(255, 220, 98, .75) 75%,
    rgba(255, 226, 50, .75) 100%
  );
  /*formfield text*/
  --input-text-color: rgba(36, 39, 47, 0.75);
  --formfield-text-color: rgba(36, 39, 47);
  --formfieldLiked-text-color: rgba(36, 39, 47);
  --formfieldLiked-text-color-inverse: rgba(36, 39, 47);
  /*formfield background*/
  --input-background: rgba(36, 39, 47, 1);
  --formfield-background: rgba(251, 254, 251, 0.24);
  --formfield-background-color-hover: rgba(251, 254, 251, 0.1);
  --formfieldLiked-background: rgba(251, 254, 251, 0.24);
  --formfieldLikedNOBG-background: rgba(251, 254, 251, 0.24);
  --formfield-background-color-error: rgba(255, 86,.08);
  /*links*/
  --link-text-color: rgba(36, 39, 47);
  --link-text-color-hover: rgba(36, 39, 47, 1);
  /*buttons*/
  --button-link-color: rgba(36, 39, 47);
  --button-link-color-hover: rgba(36, 39, 47);
  --button-link-color-inverse: rgba(36, 39, 47);
  --button-link-color-inverse-hover: rgba(36, 39, 47);
  /*backgrounds*/
  --thingsCardLiked-background: rgba(251, 254, 251, 0.12);
  --background: linear-gradient(
    30deg,
    rgba(255, 168, 202, 1) 0%,
    rgba(255, 181, 169, 1) 0%,
    rgba(255, 198, 123, 1) 36%,
    rgba(255, 220, 98, 1) 75%,
    rgba(255, 226, 50, 1) 100%
  );
  /*misc*/
  --cardShadow: 0 0.8px 1.5px rgba(0, 0, 0, 0.04),
    0 2.7px 5.1px rgba(0, 0, 0, 0.07), 0 12px 23px rgba(0, 0, 0, 0.09);
}

/*---GLOBAL----*/
.App {
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  color: var(--text-color);
  background: var(--background);
  z-index: 0;
}

.Footer{
  position:relative;
  text-align:center;
  width:100%;
  margin-bottom:4%;
  color:var(--text-color);
}

.ThemeSwitcher {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 75%;
  align-items: center;
}

.Toast {
  background-color: rgb(97, 219, 134);
  border-radius: 0px;
  display: flex;
  z-index: 3000;
  font-size: 1em;
  font-weight: 700;
}

.ThemeSwitcher .icons {
  fill: var(--button-link-color-inverse);
  opacity:.32;
}

.icons {
  fill: var(--button-link-color-inverse);
}

.icons:hover {
  fill: var(--button-link-color-inverse-hover);
  opacity:1;
  transition:.5s;
}

.iconsSelected{
  opacity:1;
}

button {
  color: var(--button-link-color);
}

button:hover {
  color: var(--button-link-color-hover);
}

a {
  color: var(--link-text-color);
}

a:hover {
  color: var(--link-text-color-hover);
}

.rainbowHighlight{
  background: var(--input-background);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
