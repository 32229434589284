.paraPadding{
  padding-left:4%;
  padding-right:4%;
  line-height:140%;
  font-size:16px;
}


#rules{
  line-height:140%;
  padding-left:4%;
}

#rules li{
  list-style-type: square;
  padding-bottom:16px;
}

#featuresTable {
  text-align:left;
  padding-left:4%;
  padding-right:4%;
}

#featuresTable td{
  padding-top:8px;
  padding-bottom:8px;
}

#featuresTable th{
  padding-bottom:8px;
}

.featuresLabel{
  font-size:12px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .05em;
}

.tableIcon{
  height:32px;
  width:32px;
}

.alignCenter{
  text-align:center;
}

.featureDivider{
  background-color:var(--faint-lines);
  height:1px;
  padding:0px !important;
}

#contact{
  margin-top:64px;
  margin-bottom:64px;
  display:flex;
  padding:4%;
  flex-direction:column;
  align-items:center;
  border: 1px solid var(--faint-lines);
  border-radius:16px;
}


#contact > div{
  display:flex;
  flex-direction:row;
}
