body{
  margin:0px;
  position:relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bodyFixed{
  position:fixed;
}

.PageWrapper {
  width: 40%;
  color:var(--formfield-text-color);
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  min-width: 300px;
}

.PageWrapper H1{
  padding-left:4%;
  padding-right:4%;
}

@media screen and (max-width: 700px) {
  .PageWrapper {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 72px;
    min-width: 300px;
  }
  .PageWrapper H1{
    margin-top:0;
    padding-left:4%;
    padding-right:4%;
    font-size:40px;
  }
}

  button{
    background:none;
    border:none;
    font-size: 16px;
    padding:0%;
    line-height:160%;
    color: var(--button-link-color);
    font-weight: 700;
    opacity:.75;
  }

  button:hover{
    background:none;
    font-size: 16px;
    border:none;
    padding:0%;
    line-height:160%;
    color: var(--button-link-color-hover);
    font-weight: 700;
    opacity:1;
  }


  .buttonborder{
    border:1px solid var(--button-link-color);
    corner-radius:2;
  }


h1{
  font-weight: bold;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: -0.02em;
}

h2{
  font-weight: bold;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: -0.01em;
}

h3{
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.01em;
}

p{
  font-size: 20px;
  line-height: 160%;
}

a{
  text-decoration-style: solid;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  color: inherit;
}


caption{
  font-size: 12px;
  line-height: 120%;
  text-align: left;
}

p.bold{
  font-weight:700;
  font-size: 20px;
  line-height: 160%;
}


caption{
  font-size: 14px;
  line-height: 140%;
  text-align: left;
}


.smallText{
  font-size:16px !important;
  line-height: 140%;
  font-weight:400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.fullCTA{
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 16px;
  color: var(--formfieldLiked-text-color);
  background: var(--thingsCardLiked-background);
  padding: 4%;
  box-shadow: var(--cardShadow);
  display:flex;
  flex-direction:column;
  align-items: center;
}

.fullCTA .aboveAuth{
  display:none !important;
}
.fullCTA button, a{
  color:var(--link-text-color-inverse)!important;
  border-color:var(--link-text-color)!important;
}

.fullCTA button:hover{
  color:var(--link-text-color-inverse)!important;
}

.fullCTA h3{
  margin-top:0;
  margin-bottom:.5em;
}

.fullCTA h4{
  margin-bottom:1.5em;
  margin-top:0;
}

/*--kofi --*/
.fullCTA .kofiimg{
  width:100px !important;
  height: 100px !important;
}

.fullCTA .btn-container{
  font-size:2em;
  text-decoration:none;
  font-weight:700;
  cursor: pointer;
}

.fullCTA .kofitext{
  color:var(--link-text-color-inverse)!important;
}

.fullCTA .btn-container:hover{
  color:black;
}

.ctaIcon{
  height:72px;
  width:72px;
  color:var(--formfieldLiked-text-color);
  opacity:.75;
  margin:0;
  margin-bottom:12px;
}

#KofiCTABig {
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.signinCTA {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width:100%;
  color:var(--formfieldLiked-text-color);
}

.signinCTA * {
  color:var(--formfieldLiked-text-color);
  justify-content: center;
  flex-direction:row;
  display: flex;

}
