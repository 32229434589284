/* ------------------------DESKTOP!!!!! -------------------------------- */
/* Links and auth positioning */


.DefaultNav {
  position: fixed;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  top: 0;
  font-size: 24px;
  font-weight: 700;
  width: 24%;
  padding:4%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
}

.NavLinks{
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  height:75%;
}

.NavLink {
  text-decoration: none;
  color: var(--text-color);
  margin-bottom: 16px;
  padding-bottom:2px;
  opacity:.72;
}

.NavLink:hover {
  text-decoration: none;
  opacity:1;
  transition:.5s;
}

.NavlinkActive{
  text-decoration: none;
  color: var(--text-color);
  border-bottom:1px solid var(--text-color);
  margin-bottom:15px;
  opacity:1;
}

.NavBottom{
  display:flex;
  flex-direction:column;
  height:50%;
  justify-content: flex-end;
}

/*------ Global auth styles ---------*/

.BuyMeForgetMe{

  width:100%;
  margin-top:12px;
}

.BuyMeForgetMeMobile{

  margin-top:12px;
  width:200px;
}


.greeting {
  font-weight: 400;
  line-height: 140%;
  color: var(--text-color);
  font-size:.7em;
  display: block;
  margin-bottom:48px;
  opacity:.72;
}




.greetingHighlight {
  font-weight: 700;
  opacity:1;
}

/* Anonymous Sign In Options */

.signInOptions {
  margin-top:16px;
  display:flex;
  flex-direction:column;
}

.signInOptions button:hover {
  opacity: 1;
}

.signInOptions button {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-size: 16px;
  line-height: 160%;
  font-weight: 700;
  background: none;
  border: 0px;
  padding-bottom: 4%;
  opacity: 0.85;
}



/* Logged In */

.logoutButton {
  background: none;
  font-size: .75em;
  line-height: 160%;
  color: var(--button-link-color);
  font-weight: 700;
  opacity: 0.85;
  border:1px solid var(--faint-lines);
  padding:8px 16px 8px 16px;
  width:200px;
  border-radius:8px;

  justify-content: center;
}

.logoutButtonMobile {
  background: none;
  border:1px solid var(--faint-lines);
  padding:8px 16px 8px 16px;
  font-size: .75em;
  line-height: 160%;
  color: var(--button-link-color);
  font-weight: 700;
  border-radius:8px;
  width:200px;
  opacity: 0.85;
}

.logoutButton:hover,
.logoutButtonMobile:hover {
  opacity: 1;
  border:1px solid var(--button-link-color);
  padding:8px 16px 8px 16px;
  border-radius:8px;
  font-size: .75em;
  transition:.5s;
}

/*------------------------------------HAMBURGER*------------------------------*/

.TopNavBar {
  position:fixed;
  top: 0;
  left:0;
  right:0;
  display: flex !important;
  z-index: 2000;
  background:none;
  justify-content: flex-end !important;
    align-items: center;
  width: 100%;
  height:72px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}


.TopNavBarBackground{
  top:0;
  left:0;
  padding:4%;
  width:100%;
  display: flex !important;
  flex-wrap:wrap;
  align-items: center;
  justify-content: flex-end;
 background: var(--topNav-background-color);
 }


 .TopNavBarBackground #NavBarGreeting{
   margin-right:24px;
   margin-top:auto;
   font-size:1em;
   line-height: 100%;
   margin-bottom:auto;
   opacity:.72;
  }

  .TopNavBarMenuOpen #NavBarGreeting{
    margin-right:24px;
    margin-top:auto;
    font-size:1em;
    line-height: 100%;
    margin-bottom:auto;
    z-index:5000;
    opacity:.72;
   }


 .TopNavBarMenuOpen{
   top:0;
   left:0;
   padding:4%;
   width:100%;
   height:inherit;
   display: flex !important;
   justify-content: flex-end !important;
   align-items: center;
   background: var(--topNav-background-color);
  }

  .PageTitleMobile{
    line-height:100%;
    display:flex;
    width:96%;
    padding-top:0;
    padding-bottom:0;
    font-size:12px;
    text-transform:uppercase !important;;
    margin-top:auto;
    margin-bottom:auto;
  }



.HamburgerIcons {
  display:flex;
  height:40px;
  width:40px;
  justify-content: center;
  opacity: 0.85;
  z-index: 1999;
  padding:0%;
}

.HamburgerIcons:hover {
  padding: 0%;
  width:40px;
  opacity: 1;
}

.bm-menu-wrap {
  background: var(--mobileNav-background);
  position: fixed !important;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100% !important;
  height: 100% !important;
  z-index: 500 !important;
  top: 0;
  right:0;
  left:0;
}


.bm-overlay {
  display: none !important;
}

/* General sidebar styles */
.bm-menu {
  font-size: 1.5em;
  font-weight: 700;
}

.bm-item-list {
  padding-left: 8%;
  padding-top: 16%;
  padding-bottom: 8%;
  width: 100% !important;
  height: 100% !important;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  z-index: -999;
}

.divider{
  background-color:var(--faint-lines);
  height:1px;
  width:100%;
  padding:0px !important;
}


.MobileNav {
  top: 0;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  height: 100%;
  padding-right:10%;
  -webkit-tap-highlight-color: transparent !important;
  outline:none !important;
}

.MobileNav:hover{
    -webkit-tap-highlight-color: transparent !important;
    outline:none;
}

.MobileNav:active{
    -webkit-tap-highlight-color: transparent !important;
    outline:none !important;
}


.NavLinksMobile {
  display: flex !important;
  flex-direction: column !important;
  height: auto;
  align-items:flex-start;
}

.NavBottomMobile {
    display:flex;
    flex-direction:column;
    height:50%;
    justify-content: flex-end;
}

/*---AUTH---*/

.aboveAuth{
  font-size:.5em;
  font-weight:400;
  line-height:160%;
}
.authButtonWrapper{
  display:flex;
  flex-direction:column;
  align-items: stretch;
  padding-top:6px;
}

.authButton{
  border:1px solid var(--faint-lines);
  display:flex;
  flex-wrap:wrap;
  padding:12px 8px 12px 8px;
  margin-top:6px;
  margin-bottom:6px;
  font-size:14px;
  border-radius:8px;
  justify-content: center;
  align-items: center;
}

.authButton:hover{
  transition:.5s;
  opacity:1;
  font-size:14px;
  padding:12px 8px 12px 8px;
  border:1px solid var(--button-link-color);

}
