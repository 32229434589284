img.kofiimg {
  display: initial !important;
  vertical-align: middle;
  height: 13px ;
  width: 20px ;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  margin-top: 0;
  margin-right: 5px;
  margin-left: 0;
  margin-bottom: 3px;
  content: url("https://ko-fi.com/img/cup-border.png");
}
.kofiimg:after {
  vertical-align: middle;
  height: 25px;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  margin-top: 0;
  margin-right: 6px;
  margin-left: 0;
  margin-bottom: 4px !important;
  content: url("https://ko-fi.com/img/whitelogo.svg");
}


span.kofitext {
  color: var(--link-text-color) !important;
  letter-spacing: -0.15px !important;
  vertical-align: middle;
  line-height: 33px !important;
  padding: 0;
}
.kofitext a {
  color: var(--link-text-color) !important;
  text-decoration: none !important;
}
.kofitext a:hover {
  color: var(--link-text-color-hover) !important;
  text-decoration: none;
}
a.kofi-button {
  padding: 0px !important;
  display:flex;
  color: var(--button-link-text);
  font-size:.5em;
  text-decoration:none;
  cursor: pointer;
  opacity:.85;
}

a.kofi-button:hover {
  cursor: pointer;
  opacity:1;
}
a.kofi-button:active {
  color: #f5f5f5 !important;
  text-decoration: none !important;
}
.kofitext img.kofiimg {
  height: 15px !important;
  width: 22px !important;
  display: initial;
  animation: kofi-wiggle 10s infinite;
}
@keyframes kofi-wiggle {
  0% {
    transform: rotate(0) scale(1);
  }
  60% {
    transform: rotate(0) scale(1);
  }
  75% {
    transform: rotate(0) scale(1.12);
  }
  80% {
    transform: rotate(0) scale(1.1);
  }
  84% {
    transform: rotate(-10deg) scale(1.1);
  }
  88% {
    transform: rotate(10deg) scale(1.1);
  }
  92% {
    transform: rotate(-10deg) scale(1.1);
  }
  96% {
    transform: rotate(10deg) scale(1.1);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}
