


  .popup-content{
  border:none !important;
  background:none!important;
  display:flex;
  flex-direction:column;
  min-width:320px;
  max-width:480px;
  width:80%;
  height:auto;
   box-shadow: var(--cardShadow);
}

.modal {
  padding:8%;
  background-color:rgb(255, 138, 125);
  border-radius:16px;
  color:var(--formfield-text-color);
}

.modal > .header {
  width: 100%;
  font-size: 1.75em;
  font-weight:700;
  padding-bottom:16px;
}
.modal > .content {
  width: 100%;
  line-height:140%;
  padding-bottom:16px;
}

.modal > .actions {
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  align-content:center;
  width: auto;
  margin: auto;
}

.actions button:nth-child(3){
  font-weight:400;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
